import { Controller } from "stimulus"
import { ANCHOR_LINK_SHOW_OFFSET } from "../../components/utils/constants.js"

export default class extends Controller {
  static values = {
    currentPage: String,
  }

  static targets = ['chapter']

  handleScroll(event) {
    if (window.pageYOffset < this.chapterTargets[0].getBoundingClientRect().top + window.pageYOffset - 20 - ANCHOR_LINK_SHOW_OFFSET && this.currentPageValue == "course") {
      const triggerEvent = new CustomEvent('content-scroll', {
        detail: {
          hash: '#course_banner'
        }
      })
      window.dispatchEvent(triggerEvent)
    }
    this.chapterTargets.forEach((target) => {
      if (window.pageYOffset > target.getBoundingClientRect().top + window.pageYOffset - 20 - ANCHOR_LINK_SHOW_OFFSET) {
        const triggerEvent = new CustomEvent('content-scroll', {
          detail: {
            hash: this.getHash(target)
          }
        })
        window.dispatchEvent(triggerEvent)
      }
    })
  }

  getHash(target) {
    if (target.hash) {
      return target.hash
    }
    const id = target.id.split(' ').find(id => id.startsWith('toc-'))
    return `#${id}`
  }
}
