import { Controller } from "stimulus"

export default class extends Controller {
  onClick() {
    if (window.ga) {
      const dataSet = this.element.dataset
      const percentage = window.scrollY / document.body.clientHeight * 100
      window.ga('send', 'event', dataSet['label'], 'scroll-to', dataSet['targetSelector'].replace('#', ''), Math.round(percentage))
    }
  }
}
