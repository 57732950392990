import { Controller } from "stimulus"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import $ from "jquery"
export default class extends Controller {

  connect() {
    $('.image-gallery_preview-container').each((key, elem) => {
      let previewContainer = $(elem);
      let thumbnailContainer = $(previewContainer.next('.image-gallery_thumbnail-container'));
      previewContainer.slick({
        swipeToSlide: true,
        infinite: true,
        accessibility: false,
        arrows: false,
        asNavFor: thumbnailContainer,
        mobileFirst: true
      });

      thumbnailContainer.slick({
        slidesToScroll: 1,
        slidesToShow: 10,
        asNavFor: previewContainer,
        arrows: false,
        accessibility: false,
        variableWidth: true,
        focusOnSelect: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 7,
            }
          }
        ]
      });
    });
  }
}
