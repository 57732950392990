import {Controller} from "stimulus"

export default class AutoInsertSignupController extends Controller {
  static targets = ['signupBlock', 'content', 'paragraph']
  static values = {
    isEnable: { type: Boolean, default: true }, // Use as Flip on/off feature
    insertIndex: { type: Number, default: 0 },
  }

  initialize() {
    if (!this.isEnableValue) {
      this.removeSignupBlockElements()
      return
    }
    // if (!this.canInsertSignup()) { return }
    
    this.initValue()
    this.initTarget()
    this.insertSignupBlock()
    this.insertSignupBlockContent()
  }

  canInsertSignup() {
    if (!this.isEnableValue) { return false }
    if (this.hasSignup()) { return false }
    return true
  }

  initValue() {
    const isMobileScreen = document.body.clientWidth < this.mobileBreakPoint()
    if (isMobileScreen) {
      this.insertIndexValue = 5
    } else {
      this.insertIndexValue = 3
    }
  }

  initTarget() {
    this.signupBlockElements().forEach((element) => {
      element.setAttribute(`data-${this.identifier}-target`, 'signupBlock')
    })
    this.postBodyContentElement().setAttribute(`data-${this.identifier}-target`, 'content')
    this.contentTarget.querySelectorAll('p').forEach((target) => {
      if (this.canParagraphTarget(target)) {
        target.setAttribute(`data-${this.identifier}-target`, 'paragraph')
      }
    })
  }

  canParagraphTarget(element) {
    return element.innerText.length > 0 && element.clientWidth === this.contentTarget.clientWidth
  }

  insertSignupBlock() {
    // insert into signupBlockTarget if it exists
    if (this.hasSignup()) { return }
    this.referenceElement().insertAdjacentHTML("afterend", this.signupBlockHTML())
  }

  insertSignupBlockContent() {
    this.signupBlockTargets.forEach((element) => {
      element.innerHTML = this.signupHTML()
    })
  }

  referenceElement() {
    if (!this.hasParagraphTarget) { return this.contentTarget.lastElementChild }

    let insertIndex = this.insertIndexValue >= this.maxParagraphIndex() ? this.maxParagraphIndex() : this.insertIndexValue
    const referenceElement = this.paragraphTargets[insertIndex - 1]
    if (this.checkNextSiblingIsList(referenceElement)) {
      return this.jumpToNextSibling(referenceElement.nextElementSibling)
    }
    return referenceElement
  }
  
  checkNextSiblingIsList(element) {
    return element.nextElementSibling && (element.nextElementSibling.tagName === 'UL' || element.nextElementSibling.tagName === 'OL')
  }

  // jump untill the next sibling is not list
  jumpToNextSibling(element) {
    if (this.checkNextSiblingIsList(element)) {
      return this.jumpToNextSibling(element.nextElementSibling)
    }
    return element
  }

  maxParagraphIndex() {
    return this.paragraphTargets.length
  }

  postBodyContentElement() {
    return this.element.querySelector('.post-body__content')
  }

  hasSignup() {
    return this.signupBlockElements().length > 0
  }

  mobileBreakPoint() {
    return 680
  }

  syncSignupControllerElements() {
    return this.element.querySelectorAll(`[data-controller="${this.syncSignupControllerIdentifier()}"]`)
  }

  removeSignupBlockElements() {
    this.signupBlockElements().forEach(element => element.remove())
  }

  syncSignupControllerIdentifier() {
    return `sync-signup-in-post`
  }

  signupBlockHTML() {
    return `<div data-content-block-type="Signup" data-${this.identifier}-target="signupBlock" class="content-block content-block-signup"></div>`
  }

  signupBlockElements() {
    return this.element.querySelectorAll(`[data-content-block-type="Signup"]`)
  }

  signupHTML() {
    return `
      <div data-controller="${this.syncSignupControllerIdentifier()}" class="full-section mb-8">
        <div class="h-[390px] w-full max-w-[850px] md:h-[300px] overflow-hidden rounded-lg">
          <div data-controller="sign-up" class="sign-up-component flex h-full w-full bg-[#191919] text-base">
            <div class="flex h-full w-full md:w-1/2 flex-col items-center justify-center xsm:p-6 sm:p-12 md:p-4 lg:p-8 m-auto">
              <div data-${this.syncSignupControllerIdentifier()}-target="title" class="text-white font-family-polysan-wide w-full text-3xl"></div>
              <div data-${this.syncSignupControllerIdentifier()}-target="tease" class="text-white mt-1 w-full text-base font-family-polysan-regular"></div>
              <div class="input-block mt-3 flex w-full flex-col items-center justify-center gap-y-3 md:flex-row">
                <input type="email" name="email" id="email" class="bg-[#191919] font-family-polysan-regular text-white placeholder:text-grey-500 h-12 w-full rounded border-none indent-2.5 text-base outline active:outline focus:outline outline-1 active:outline-1 focus:outline-1 outline-gray-400 active:outline-gray-400 focus:outline-gray-400 md:mr-2 md:w-2/3" placeholder="Enter your email" required="required" />
                <button name="button" type="submit" data-action="sign-up#sendData" class="text-green-text-button font-family-polysan-regular h-12 w-full cursor-pointer rounded border-none bg-[#9CEE69] text-base hover:bg-lime-500 md:w-1/3">Sign up</button>
              </div>
              <div class="suffix text-grey-500 mt-3 w-full text-base">
                <span class="suffix-content w-full font-family-polysan-regular">
                  Unsubscribe at any time.
                  <a data-${this.syncSignupControllerIdentifier()}-target="policy" rel="noopener noreferrer" class="text-grey-500 custom-underline hover:text-gray-300" href="" previewlistener="true">Privacy Policy. </a>
                </span>
              </div>
            </div>
            <div class="background-image hidden md:flex md:w-1/2">
              <img data-${this.syncSignupControllerIdentifier()}-target="image" alt="Side image" class="h-full w-full object-cover object-left !m-0 !rounded-none" style="background: none;" src="" />
            </div>
          </div>
        </div>
      </div>
    `
  }

}
