import { Controller } from "stimulus"
import { ANCHOR_LINK_SHOW_OFFSET } from "../../components/utils/constants.js"

export default class extends Controller {
  connect() {
    this.hidePostOnScrollFloatingButtonsGroup()
    this.linkTargets = Array.from(this.element.querySelectorAll(".floating-toc__link"))
    this.contentContainerTarget = this.element.querySelector(".floating-toc__list")
    this.attachEvents()
  }

  attachEvents() {
    if (!this.element.dataset["isCourse"]) {
      this.linkTargets.forEach(link => {
        link.addEventListener("click", this.onClick.bind(this))
      })
    }
    window.addEventListener("content-scroll", this.handlePageScroll.bind(this))
  }

  handlePageScroll (event) {
    if (event.detail.hash == "#course_banner") {
      this.selectContentTitle(this.linkTargets[0])
    }
    let target = this.linkTargets.find(linkTarget => linkTarget.hash == (event.detail.hash || event.target.hash));
    let selectedTarget = document.querySelector(".floating-toc__highlighted")
    if(selectedTarget == null || selectedTarget.querySelector("a").hash !== target.hash) {
      this.selectContentTitle(target)
    }
  }

  selectContentTitle (target) {
    if (target == undefined) {
      return
    }
    this.linkTargets.forEach(linkTarget => {
      linkTarget.parentNode.classList.remove("floating-toc__highlighted")
    });
    const parent = target.parentNode
    parent.classList.add("floating-toc__highlighted")
    let targetTop = parent.offsetTop
    if (parent.classList.contains("table-of-contents__lesson")) {
      targetTop = parent.offsetParent.offsetTop + parent.offsetTop
    }
    const targetScrollY = targetTop - ANCHOR_LINK_SHOW_OFFSET
    this.contentContainerTarget.scrollTo(0, Math.max(targetScrollY, 0))
  }

  onClick(event) {
    if (window.ga) {
      const targetName = event.target.href.split("#")[1]
      const percentage = window.scrollY / document.body.clientHeight * 100
      window.ga('send', 'event', 'table-of-contents', 'scroll-to', targetName, Math.round(percentage))
    }
  }

  collapseTableOfContents() {
    this.showPostOnScrollFloatingButtonsGroup()
    this.element.classList.add("post-table-of-content__collapsed")
  }

  expandTableOfContents() {
    this.element.classList.remove('post-table-of-content__collapsed')
    this.hidePostOnScrollFloatingButtonsGroup()
  }

  hidePostOnScrollFloatingButtonsGroup() {
    if (this.getButtonsGroupContainer()) {
      this.getButtonsGroupContainer().classList.add('hide')
    }
  }

  showPostOnScrollFloatingButtonsGroup() {
    if (this.getButtonsGroupContainer()) {
      this.getButtonsGroupContainer().classList.remove('hide')
    }
  }

  getButtonsGroupContainer() {
    if (this.buttonsGroupContainer == undefined) {
      this.buttonsGroupContainer = document.querySelector(".post-on-scroll-floating-buttons-group__container")
    }
    return this.buttonsGroupContainer
  }
}
