import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "token",
    'likeButton',
    "dislikeButton",
    "likeIcon",
    "dislikeIcon"
  ]

  connect() {
    this.id = this.element.dataset['id']
    this._setCurrentState()
  }

  like() {
    if(this._hasAlreadyVoted()) {
      return
    }

    this._submitFeedback(this._buildParams({
      like_action: 'process_like'
    }), () => { this._triggerGAEvent('thumbs_up') })
  }

  dislike() {
    if(this._hasAlreadyVoted()) {
      return
    }

    this._submitFeedback(this._buildParams({
      like_action: 'process_dislike'
    }), () => { this._triggerGAEvent('thumbs_down') })
  }

  _setCurrentState() {
    const vote = this._getVote()
    this._clearButtonHighlights()
    this._clearDisableButtons()
    switch(vote) {
      case "like":
        this._toggleIconHighlight(this.likeIconTarget)
        this._toggleDisableButton(this.dislikeButtonTarget)
        break
      case "dislike":
        this._toggleIconHighlight(this.dislikeIconTarget)
        this._toggleDisableButton(this.likeButtonTarget)
        break
    }
  }

  _buildParams(params) {
    const formData = new FormData()
    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value)
    }

    formData.append("_method", "PUT")
    formData.append('authenticity_token', this.tokenTarget.value)
    return formData
  }

  _toggleIconHighlight(button) {
    button.classList.toggle("feedback-btn__highlight")
  }

  _toggleDisableButton(button) {
    button.classList.toggle("btn-disable")
  }

  _clearDisableButtons() {
    this.likeButtonTarget.classList.remove("btn-disable")
    this.dislikeButtonTarget.classList.remove("btn-disable")
  }

  _clearButtonHighlights() {
    this.likeIconTarget.classList.remove("feedback-btn__highlight")
    this.dislikeIconTarget.classList.remove("feedback-btn__highlight")
  }

  _hasAlreadyVoted() {
    return !!(window.localStorage.getItem(this._getVoteKey()))
  }

  _submitFeedback(data, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/post_votes/${this.id}`, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.localStorage.setItem(this._getVoteKey(), xhr.responseText)
        this._setCurrentState()
        callback()
      }
    };
    xhr.send(data);
  }

  _getVote() {
    const vote = window.localStorage.getItem(this._getVoteKey())
    if(vote) {
      return JSON.parse(vote).vote
    }

    return ''
  }

  _getVoteKey() {
    return `like_votee${this.id}`
  }

  _triggerGAEvent(event_name) {
    ga('send', 'event', 'thumb_rating', event_name)
  }
}
