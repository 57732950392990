import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    this.handleYoutubeAndWistiaVideoPreviewImage()
    this.showMoreTocToggle()
  }

  showMoreTocToggle() {
    const els = this.element.querySelectorAll('.visual-toc__show-more')
    if(els.length) {
      els.forEach(el => {
        const list = el.parentNode.querySelector('ul')
        const height = list.classList.contains('visual-toc__thumbnail-theme') ? '410px' : '210px'
        list.style.maxHeight = height
        const divider = el.parentNode.querySelector('.visual-toc__divider')
        if (divider) divider.remove()
        el.addEventListener('click', () => {
          list.classList.toggle('show-full')
          if(!list.classList.contains('show-full')) {
            list.style.maxHeight = height
            el.textContent = '+ Show more'
          } else {
          // Temporarily set maxHeight to 'none' to force reflow and get the correct scrollHeight
          list.style.maxHeight = 'none'
          const scrollHeight = list.scrollHeight
          list.style.maxHeight = scrollHeight + 'px'
          el.textContent = '- Show less'
          }
        })
      }) 
    }
  }

  handleYoutubeAndWistiaVideoPreviewImage = () => {
    document.querySelectorAll('.video-preview-image img, .video-preview-image svg').forEach(videoPreviewImage => {
      videoPreviewImage.addEventListener('click', () => {
        const videoWrapper = videoPreviewImage.closest('figure')
        const videoPreviewWrapper = videoPreviewImage.parentElement
        const videoIframe = videoPreviewWrapper.previousElementSibling
        if (videoWrapper && videoIframe.attributes && videoIframe.attributes['data-src']) {
          const videoEmbedUrl = videoIframe.attributes['data-src'].value
          videoWrapper.innerHTML = `<iframe src='${videoEmbedUrl}' frameborder='0' allowfullscreen='allowfullscreen' webkitallowfullscreen='webkitallowfullscreen' mozallowfullscreen='mozallowfullscreen'></iframe>`
        }
        videoPreviewWrapper.remove()
      })
    })
  }
}
