import { Controller } from "stimulus"

const AD_TAGS_PARENTAL_PSEUDO_SELECTOR = '.post-body__content > .content-block-wysi';

export default class extends Controller {
  connect() {
    const adsToBePlaced = this.element.querySelectorAll('[data-promotion-placement]')
    for (const ad of adsToBePlaced) {
      this._placeAd(ad)
    }
  }

  _placeAd(ad) {
    const placementTag = ad.dataset.placementTag
    const placement = parseInt(ad.dataset.promotionPlacement, 10)
    if(placementTag === 'p') {
      this._placeAdAtParagraph(placement, ad)
    } else {
      this._placeAdAtHeading(placement, ad)
    }
  }

  _placeAdAtParagraph(placement, ad) {
    if(this._numberOfParagraphElements() < placement) {
      ad.remove()
      return
    }
    const paragraph = this._paragraphElements()[placement - 1]
    paragraph.parentNode.insertBefore(ad, paragraph.nextElementSibling)
    ad.classList.remove("is-hidden");
    return
  }

  _placeAdAtHeading(placement, ad) {
    if (this._numberOfHeadingElements() < placement) {
      ad.remove()
      return
    }

    const heading = this._headingElements()[placement - 1]
    heading.parentNode.insertBefore(ad, heading)
    ad.classList.remove("is-hidden");
  }

  _numberOfHeadingElements() {
    if (this._numberOfHeadingElementsCached === undefined) {
      this._numberOfHeadingElementsCached = this._headingElements().length
    }
    return this._numberOfHeadingElementsCached
  }

  _numberOfParagraphElements() {
    if (this._numberOfParagraphElementsCached === undefined) {
      this._numberOfParagraphElementsCached = this._paragraphElements().length
    }
    return this._numberOfParagraphElementsCached
  }

  _headingElements() {
    if (this._headingElementsCached === undefined) {
      this._headingElementsCached = this.element.querySelectorAll(`${AD_TAGS_PARENTAL_PSEUDO_SELECTOR} h2, ${AD_TAGS_PARENTAL_PSEUDO_SELECTOR} h3`)
    }
    return this._headingElementsCached
  }

  _paragraphElements() {
    if (this._paragraphElementsCached === undefined) {
      this._paragraphElementsCached = this.element.querySelectorAll(`${AD_TAGS_PARENTAL_PSEUDO_SELECTOR} p`)
    }
    return this._paragraphElementsCached
  }

  checkSidebarAdDisplayCondition() {
    // if the post has minimum headingElementsThreshold headings present, we want to display the layout__sidebar-ad-3 ad.
    const headingElementsThreshold = 8
    if (this._numberOfHeadingElements() >= headingElementsThreshold) {
      const triggerEvent = new CustomEvent("show-sidebar-ad")
      window.dispatchEvent(triggerEvent)
    }
  }
}
