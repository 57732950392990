import { Controller } from "stimulus"
import { bind } from 'underscore'
import * as $ from 'jquery'

export default class extends Controller {
  static targets = ['stickyElement']

  connect() {
    this.el = $(this.element)
    this.scrollContainer = $(window)
    this.stickyBottom = this.element.dataset['stickyBottom'] ? true : false;

    const $layout__offset_content_with_sidebar = this.el.closest('.layout__offset-content-with-sidebar')
    const $scrollPoint = $(this.element.dataset['scrollHeightDefiner'])
    const $mainContent = $scrollPoint.length ? $scrollPoint : this.el.closest('main.content')
    this.heightDefiner = $layout__offset_content_with_sidebar.length > 0 ? $layout__offset_content_with_sidebar : $mainContent.length > 0 ? $mainContent : $(this.el.data('height-definer'))
    this.innerContainer = this.stickyElementTarget ? $(this.stickyElementTarget) : $(this.el.data('inner-container'))

    if(this.scrollContainer?.length < 1) {
      throw "scrollContainer is required but missing"
    }

    if(this.heightDefiner?.length < 1) {
      throw "heightDefiner is required but missing"
    }

    if(this.innerContainer?.length < 1) {
      throw "innerContainer is required but missing"
    }

    this.scrollContainer.on('scroll', bind(this.setPosition, this))
  }

  setPosition() {
    this.origOffsetTop = this.heightDefiner.offset()?.top + (this.stickyBottom ? 0 : parseInt(this.el.css('top')))
    this.lowestRelativePosition = this.heightDefiner.outerHeight(true) - (this.stickyBottom ? 0 : this.innerContainer.outerHeight(true))

    this.el.toggleClass('sticky-block--pin-top', this._preRange())
    this.el.toggleClass('sticky-block--pin-bottom', this._postRange())
    this.el.toggleClass('sticky-block--floating', this._stickyRange())
  }

  _preRange() {
    return (this.scrollContainer.scrollTop() <= this.origOffsetTop)
  }

  _stickyRange() {
    return (!this._preRange() && !this._postRange())
  }

  _postRange(){
    return(this.scrollContainer.scrollTop() + (this.stickyBottom ? window.screen.height : 0) > this.origOffsetTop + this.lowestRelativePosition)
  }
}
