import { Controller } from 'stimulus'
import { ANCHOR_LINK_SHOW_OFFSET } from "../../components/utils/constants.js"

export default class extends Controller {
	connect() {
	  this.attachEvents()
	}

  attachEvents() {
    document.querySelectorAll('a[href^="#"]').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault()
        const hash = event.currentTarget.hash
        this.animate(180, hash)
      })
    })

    window.addEventListener('popstate', (event) => {
      const hash = event.target.location.hash
      if (hash == '' || hash == null) {
        this.animate(180, "#page-body")
      } else {
        this.animate(180, hash)
      }
    })
  }

  animate(duration, hash) {
    const targetIdentifier = hash.replace('#', '')
    let targetElement = document.getElementById(targetIdentifier) || document.querySelector('[name=' + targetIdentifier + ']')
    let targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset
    let targetY = document.body.scrollHeight - targetPosition < window.innerHeight ? document.body.scrollHeight - window.innerHeight : targetPosition
    this.smoothScroll(targetY - ANCHOR_LINK_SHOW_OFFSET, duration, () => {
      if (hash !== "#page-body") {
        window.location.hash = hash
      }
    })
  }

  smoothScroll(targetPos, time, onComplete) {
    const pageYOffset = window.pageYOffset
    let start = null
    let currentYPosition = null

    window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start
        let progress = currentTime - start
        if (pageYOffset < targetPos) {
          currentYPosition = pageYOffset + ((targetPos - pageYOffset) * progress / time)
        } else {
          currentYPosition = pageYOffset - ((pageYOffset - targetPos) * progress / time)
        }

        window.scrollTo(0, currentYPosition)

        if (progress < time) {
          window.requestAnimationFrame(step)
        } else {
          window.scrollTo(0, targetPos)
          onComplete()
        }
    });
  }
}
