import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['ad']
  }

  connect() {
    const triggerEvent = new CustomEvent('count-headings')
    window.dispatchEvent(triggerEvent)
  }


  showSidebarAd(){
    this.adTarget.classList.remove('avert--hidden')
  }
}
