import { Controller } from "stimulus"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import $ from "jquery"

export default class extends Controller {
  connect() {
    const container = $(this.element.querySelector('.item-list--thumbnail-wrapper'))
    if (this.element.dataset["carousel"] == "true") {
      container.slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        dots: true,
        slidesToScroll: 1,
        appendDots: $(this.element.querySelector('.item-list--thumbnail-dots-container')),
        nextArrow: '<a href="#" class="item-list--thumbnail-carousel-btn item-list--thumbnail-next-btn fa fa-chevron-right"></a>',
        prevArrow: '<a href="#" class="item-list--thumbnail-carousel-btn item-list--thumbnail-prev-btn fa fa-chevron-left"></a>'
      });
    }
  }
}
