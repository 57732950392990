import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.linkTargets = Array.from(this.element.querySelectorAll(".visual-toc-list li a"))
    this.attachEvents()
  }

  attachEvents() {
    this.linkTargets.forEach(link => {
      link.addEventListener("click", this.onClick.bind(this))
    })
  }

  onClick(event) {
    if (window.ga) {
      const targetName = event.currentTarget.href.split("#")[1]
      const percentage = window.scrollY / document.body.clientHeight * 100
      window.ga('send', 'event', 'table-of-contents', 'inline-scroll-to', targetName, Math.round(percentage))
    }
  }
}
