import { Controller } from "stimulus"
import Tether from 'tether'
import { onDesktop } from 'utils/screen_resolution_util'

export default class extends Controller {
  connect() {
    if (onDesktop()) {
      const adLeft = this.element.querySelector('.sticky-ad-sky-left')
      const adRight = this.element.querySelector('.sticky-ad-sky-right')
      const post = this.element

      if (adLeft) {
        const postSidebarLeft = this.element.querySelector('.sticky-ad-target-sky-left')

        this.attachTether(adLeft, postSidebarLeft, post);
      }

      if (adRight) {
        const postSidebarRight = this.element.querySelector('.sticky-ad-target-sky-right');

        this.attachTether(adRight, postSidebarRight, post);
      }
    }
  }

  attachTether(ad, target, container) {
    new Tether({
      element: ad,
      target:  target,
      attachment: 'top left',
      targetAttachment: 'top left',
      constraints: [
        {to: 'window', pin: ['top']},
        {to: container, pin: ['bottom']},
        {to: target, pin: ['top']}
      ]
    })
  }
}
